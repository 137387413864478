import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Box,
  Autocomplete,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/controls/Button";
import { theme } from "../../../../styles/theme";
import { makeStyles, styled } from "@mui/styles";
import SelectMenu from "../../../../components/controls/SelectMenu";
import { transformOptions } from "../../../../services/globalService";
import SelectWithCheckbox from "../../../../components/controls/SelectWithCheckbox";
import TextInput from "../../../../components/controls/TextField";
import { useSelector, useDispatch } from "react-redux";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import role from "../../../../redux/actions/role";
import { useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReusableDialog from "../../../../components/controls/ReusableDialog";

const useStyles = makeStyles({
  content: theme.typography.b1Regular,
  mainText: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    paddingTop: "10px",
  },
  subText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
  },
  heading: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    padding: "20px 0px",
  },
  switchContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "15px",
  },
  field: {
    marginBottom: "0px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "48px !important",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      height: "17.2px!important",
    },
  },
  field1: {
    marginTop: 8,
    borderRadius: "5px !important",
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between !important",
    padding: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px",
  },
  urlContainer: {
    backgroundColor: "#f6f6f6",
    padding: "10px",
    borderRadius: "5px",
  },
});

const PublishSettingsWizard = ({ open, setOpen, selectedGroups, mainSchema, setSnacktext, setOpenSnackbar }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);
  const roleState = useSelector((state) => state.role);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { linkId } = useParams();
  const [loading, setLoading] = useState(false);

  const [branchMapping, setBranchMapping] = useState([]);
  const [idproofDocTypeOptions, setIdproofDocTypeOptions] = useState([]);
  const [degreeMapping, setDegreeMapping] = useState([]);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [publishedUrl, setPublishedUrl] = useState("");
  const [openPublishUrlModal, setOpenPublishUrlModal] = useState(false);
  const [formData, setFormData] = useState({
    application: {
      rdType: "campusRecruitment",
      startDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // Set initial start date 24 hours after current date
      deadline: new Date(new Date().getTime() + 48 * 60 * 60 * 1000), // Set initial deadline 48 hours after current date
      testStartDate: null,
      testDeadline: new Date(new Date().getTime() + 72 * 60 * 60 * 1000), // Set initial test deadline 7 days after current date
    },
    dataCollection: {
      collectPhone: "no",
      allowedEmailDomains: [],
      latestEduScoreScale: "flexible",
      latestEduBranches: [],
      idproofDocType: [],
    },
    automatedShortlisting: false,
    shortlistingCriteria: {
      experience: {
        on: false,
        value: "0",
      },
      degree: {
        on: false,
        value: "Bachelors",
      },
      branch: {
        on: false,
        value: [],
      },
      latestEduScore: {
        on: false,
        value: "",
      },
      skillMatchScore: {
        on: false,
        value: "",
      },
      skill: {
        on: false,
        value: [],
      },
      approvalMethod: "manual",
    },
  });

  useEffect(() => {
    if (linkId) {
      dispatch(role.getSettings({ linkId }));
    }
  }, [linkId]);

  // function to transform options for select menu
  const convertKeyValueOption = (arr) => {
    const transformed = arr.reduce((acc, item) => {
      acc[item] = item;
      return acc;
    }, {});
    return transformed;
  };

  useEffect(() => {
    if (mainSchema?.properties?.group_ID) {
      const idproofArr = mainSchema?.properties?.group_ID?.properties?.docType?.enum;
      const transformed = convertKeyValueOption(idproofArr);
      setIdproofDocTypeOptions(transformed);
    }
    if (mainSchema?.properties?.group_latestEdu?.properties?.branch) {
      const branchArr = mainSchema?.properties?.group_latestEdu?.properties?.branch?.enum;
      const transformed = convertKeyValueOption(branchArr);
      setBranchMapping(transformed);
    }

    if (mainSchema?.properties?.group_latestEdu?.properties?.degree) {
      const degreeArr = mainSchema?.properties?.group_latestEdu?.properties?.degree?.enum;
      const transformed = convertKeyValueOption(degreeArr);
      setDegreeMapping(transformed);
    }
  }, [mainSchema]);

  useEffect(() => {
    if (authState?.skillsConfig) {
      const skillsConfig = authState.skillsConfig;
      const skills = Object.keys(skillsConfig)
        .filter((key) => skillsConfig[key].isCandidateSkill)
        .map((key) => ({ id: key, fullName: skillsConfig[key].fullName }));
      setCandidateSkills(skills);
    }
  }, [authState?.skillsConfig]);

  const steps = ["Purpose", "Data Collection", "Automated Shortlisting", "Set Dates & Publish"];

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // set initial values based on previous saved settings
  useEffect(() => {
    if (roleState?.getSettingsSuccess?.application?.rdType) {
      const settings = roleState?.getSettingsSuccess;
      console.log(settings, "settings123");
      setFormData((prevData) => ({
        ...prevData,
        application: {
          ...prevData.application,
          rdType: settings?.application?.rdType,
          startDate: new Date(settings?.application?.startDate),
          deadline: new Date(settings?.application?.deadline),
          testStartDate: new Date(settings?.application?.testStartDate),
          testDeadline: new Date(settings?.application?.testDeadline),
        },
      }));
      setFormData((prevData) => ({
        ...prevData,
        dataCollection: {
          ...prevData.dataCollection,
          collectPhone: settings?.dataCollection?.collectPhone,
          allowedEmailDomains: settings?.dataCollection?.allowedEmailDomains,
          latestEduScoreScale: settings?.dataCollection?.latestEduScoreScale || "flexible",
          latestEduBranches: settings?.dataCollection?.latestEduBranches || Object.keys(branchMapping),
          idproofDocType: settings?.dataCollection?.idproofDocType || Object.keys(idproofDocTypeOptions),
        },
      }));
      setFormData((prevData) => ({
        ...prevData,
        automatedShortlisting: settings?.automatedShortlisting,
      }));
      setFormData((prevData) => ({
        ...prevData,
        shortlistingCriteria: {
          ...prevData.shortlistingCriteria,
          experience: {
            ...prevData.shortlistingCriteria.experience,
            on: settings?.shortlistingCriteria?.experience?.on,
            value: settings?.shortlistingCriteria?.experience?.value || "0",
          },
          degree: {
            ...prevData.shortlistingCriteria.degree,
            on: settings?.shortlistingCriteria?.degree?.on,
            value: settings?.shortlistingCriteria?.degree?.value || "Bachelors",
          },
          branch: {
            ...prevData.shortlistingCriteria.branch,
            on: settings?.shortlistingCriteria?.branch?.on,
            value: settings?.shortlistingCriteria?.branch?.value || Object.keys(branchMapping),
          },
          latestEduScore: {
            ...prevData.shortlistingCriteria.latestEduScore,
            on: settings?.shortlistingCriteria?.latestEduScore?.on,
            value: settings?.shortlistingCriteria?.latestEduScore?.value || "0",
          },
          skillMatchScore: {
            ...prevData.shortlistingCriteria.skillMatchScore,
            on: settings?.shortlistingCriteria?.skillMatchScore?.on,
            value: settings?.shortlistingCriteria?.skillMatchScore?.value || "0",
          },
          skill: {
            ...prevData.shortlistingCriteria.skill,
            on: settings?.shortlistingCriteria?.skill?.on,
            value: settings?.shortlistingCriteria?.skill?.value,
          },
          approvalMethod: settings?.shortlistingCriteria?.approvalMethod,
        },
      }));
    }
  }, [roleState?.getSettingsSuccess, branchMapping]);

  const isPublishDisabled = () => {
    const { startDate, deadline, testStartDate, testDeadline } = formData.application;
    let maxDate = testStartDate ? Math.max(deadline, testStartDate) : deadline;
    maxDate = maxDate + 60 * 60 * 1000;

    // Validation checks
    const isStartDeadlineValid = startDate && deadline ? new Date(deadline) > new Date(startDate) : true;
    const isTestDatesValid = testStartDate && testDeadline ? new Date(testDeadline) > new Date(testStartDate) : true;
    const isTestDeadlineValid = testDeadline && maxDate ? new Date(testDeadline) > new Date(maxDate) : true;

    return !(isStartDeadlineValid && isTestDatesValid && isTestDeadlineValid);
  };

  const handlePublish = () => {
    setLoading(true);

    // check and reset on values based on selected groups
    const modifiedFormData = {
      ...formData,
      shortlistingCriteria: {
        ...formData.shortlistingCriteria,
        experience: {
          ...formData.shortlistingCriteria.experience,
          on: selectedGroups.includes("group_resume") && formData.shortlistingCriteria.experience.on,
        },
        degree: {
          ...formData.shortlistingCriteria.degree,
          on:
            (selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) &&
            formData.shortlistingCriteria.degree.on,
        },
        branch: {
          ...formData.shortlistingCriteria.branch,
          on: selectedGroups.includes("group_latestEdu") && formData.shortlistingCriteria.branch.on,
        },
        latestEduScore: {
          ...formData.shortlistingCriteria.latestEduScore,
          on: selectedGroups.includes("group_latestEdu") && formData.shortlistingCriteria.latestEduScore.on,
        },
        skillMatchScore: {
          ...formData.shortlistingCriteria.skillMatchScore,
          on: selectedGroups.includes("group_resume") && formData.shortlistingCriteria.skillMatchScore.on,
        },
        skill: {
          ...formData.shortlistingCriteria.skill,
          on: selectedGroups.includes("group_resume") && formData.shortlistingCriteria.skill.on,
        },
      },
    };

    const data = {
      settings: modifiedFormData,
    };

    dispatch(role.saveSettingsAndPublish({ linkId, data }));
  };

  useEffect(() => {
    if (roleState?.saveSettingsAndPublishSuccess) {
      setOpenSnackbar(true);
      setSnacktext("Settings saved and published successfully");
      setOpen(false);
      dispatch(role.getSettings({ linkId }));
      dispatch(role.clearSaveSettingsAndPublishSuccess());
      setPublishedUrl(roleState?.saveSettingsAndPublishSuccess?.url);
      setOpenPublishUrlModal(true);
      setCurrentStep(0);
      setLoading(false);
    }
  }, [roleState?.saveSettingsAndPublishSuccess]);

  useEffect(() => {
    if (roleState?.saveSettingsAndPublishFailure) {
      setLoading(false);
    }
  }, [roleState?.saveSettingsAndPublishFailure]);

  const handleCopyUrl = () => {
    setSnacktext("");
    navigator.clipboard.writeText(publishedUrl);
    setTimeout(() => {
      setOpenSnackbar(true);
      setSnacktext("URL copied to clipboard");
      setOpenPublishUrlModal(false);
    }, 200);
  };

  const handleCloseDialog = () => {
    setCurrentStep(0);
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle id="dialog-title">
          Settings
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content} sx={{ minHeight: "60vh" }}>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {currentStep === 0 && <Step1 formData={formData} setFormData={setFormData} classes={classes} />}
            {currentStep === 1 && (
              <Step2
                formData={formData}
                setFormData={setFormData}
                classes={classes}
                selectedGroups={selectedGroups}
                mainSchema={mainSchema}
                branchMapping={branchMapping}
                idproofDocTypeOptions={idproofDocTypeOptions}
              />
            )}
            {currentStep === 2 && (
              <Step3
                formData={formData}
                setFormData={setFormData}
                classes={classes}
                selectedGroups={selectedGroups}
                mainSchema={mainSchema}
                candidateSkills={candidateSkills}
                degreeMapping={degreeMapping}
                branchMapping={branchMapping}
                convertKeyValueOption={convertKeyValueOption}
              />
            )}
            {currentStep === 3 && <Step4 formData={formData} setFormData={setFormData} classes={classes} />}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          {currentStep > 0 && <Button onClick={prevStep} color="btnCls1" text="Previous" variant="outlined" />}
          {currentStep < steps.length - 1 && <Button onClick={nextStep} color="btn1" text="Next" />}
          {currentStep === steps.length - 1 && (
            <Button
              onClick={() => handlePublish()}
              color={isPublishDisabled() || loading ? "disablebtn1" : "btn1"}
              text="Publish"
              disabled={isPublishDisabled() || loading}
            />
          )}
        </DialogActions>
      </Dialog>
      <ReusableDialog
        open={openPublishUrlModal && publishedUrl}
        handleClose={() => setOpenPublishUrlModal(false)}
        fullWidth={true}
        maxWidth="sm"
        title={
          <>
            Published Successfully
            <IconButton
              aria-label="close"
              onClick={() => setOpenPublishUrlModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
        content={
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Grid className={classes.urlContainer}>
              <Typography variant="body1" sx={{ fontFamily: "monospace", wordBreak: "break-all", mr: 1 }}>
                {publishedUrl}
              </Typography>
            </Grid>
            <IconButton onClick={handleCopyUrl} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Grid>
        }
      />
    </>
  );
};

const Step1 = ({ formData, setFormData, classes }) => {
  const rdTypes = {
    campusRecruitment: "Campus Recruitment",
    lateralHiring: "Lateral Hiring",
  };
  const rdTypesTrans = transformOptions(rdTypes);

  return (
    <div>
      <Typography variant="h6" className={classes.heading}>
        Purpose
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography className={classes.mainText}>Recruitment Drive Type</Typography>
          <Typography className={classes.subText}>
            Does this recruitment drive apply to Fresher Hiring/Campus Recruitment
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SelectMenu
            selectvalue={rdTypes}
            selectHandleChange={(value) =>
              setFormData((prevData) => ({ ...prevData, application: { ...prevData.application, rdType: value.id } }))
            }
            prevSelectedValue={rdTypesTrans.find((option) => option.id === formData.application.rdType)}
          />
        </Grid>
        {formData.application.rdType === "campusRecruitment" && (
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Typography variant="body1">
              You have selected Recruitment Drive Type as <b>Campus Recruitment</b>. This is suitable for hiring
              freshers with little or no experience. <b>Profile</b> section evaluation will be turned off for the test
              invites sent through this recruitment drive.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const Step2 = ({ formData, setFormData, classes, selectedGroups, branchMapping, idproofDocTypeOptions }) => {
  const updateDataCollection = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      dataCollection: {
        ...prevData.dataCollection,
        [key]: value,
      },
    }));
  };

  const collectPhoneOptions = {
    yes: "Yes",
    no: "No",
  };

  const latestEduScoreScaleOptions = {
    cgpa: "CPI/CGPA (10 point scale)",
    gpa: "GPA (4 point scale)",
    percentage: "Percentage (100 point scale)",
    flexible: "Flexible",
  };

  const collectPhoneOptionsTrans = transformOptions(collectPhoneOptions);
  const latestEduScoreScaleOptionsTrans = transformOptions(latestEduScoreScaleOptions);

  return (
    <div>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Data Collection</Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={8}>
          <Typography className={classes.mainText}>Collect Mobile/Phone number</Typography>
          <Typography className={classes.subText}>Providing phone number is mandatory</Typography>
        </Grid>
        <Grid item xs={4}>
          <SelectMenu
            selectvalue={collectPhoneOptions}
            selectHandleChange={(value) => updateDataCollection("collectPhone", value.id)}
            prevSelectedValue={collectPhoneOptionsTrans.find(
              (option) => option.id === formData.dataCollection.collectPhone
            )}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.mainText}>Allowed Email Domains</Typography>
          <Typography className={classes.subText}>
            Candidate email address must belong to one of these domains
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="allowedEmailDomains"
            options={[]}
            freeSolo
            multiple
            getOptionLabel={(option) => option}
            value={formData.dataCollection.allowedEmailDomains}
            onChange={(e, value) => updateDataCollection("allowedEmailDomains", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="allowedEmailDomains"
                name="Allowed Email Domains"
                placeholder="Enter domains and press enter"
                className={formData?.dataCollection?.allowedEmailDomains?.length === 0 ? classes.field : classes.field1}
              />
            )}
          />
        </Grid>
        {selectedGroups.includes("group_latestEdu") && (
          <>
            <Grid item xs={8}>
              <Typography className={classes.mainText}>Latest Education Score scale</Typography>
              <Typography className={classes.subText}>
                The scale on which candidates should provide the latest education scores
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <SelectMenu
                selectvalue={latestEduScoreScaleOptions}
                selectHandleChange={(value) => updateDataCollection("latestEduScoreScale", value.id)}
                prevSelectedValue={latestEduScoreScaleOptionsTrans.find(
                  (option) => option.id === formData.dataCollection.latestEduScoreScale
                )}
              />
              {/* {formik.touched.settings?.dataCollection?.latestEduScoreScale &&
                formik.errors.settings?.dataCollection?.latestEduScoreScale && (
                  <Typography variant="caption" color="error">
                    {formik.errors.settings.dataCollection.latestEduScoreScale}
                  </Typography>
                )} */}
            </Grid>
          </>
        )}
        {selectedGroups.includes("group_latestEdu") && (
          <>
            <Grid item xs={8}>
              <Typography className={classes.mainText}>Latest Education Branches</Typography>
              <Typography className={classes.subText}>Select all the permitted branches</Typography>
            </Grid>
            <Grid item xs={4}>
              {branchMapping && (
                <SelectWithCheckbox
                  title={"Select Branches"}
                  options={branchMapping}
                  handleSelectOption={(value) => updateDataCollection("latestEduBranches", value)}
                  width={"100%"}
                  checkLength={formData?.dataCollection.latestEduBranches?.length}
                  BgColor={formData?.dataCollection.latestEduBranches?.length !== 0}
                  defaultValue={formData?.dataCollection.latestEduBranches || []}
                  disabledCapitalize
                  handleClearAll={() => updateDataCollection("latestEduBranches", [])}
                  handleSelectAll={() => updateDataCollection("latestEduBranches", Object.keys(branchMapping))}
                />
              )}
              {/* {formik.touched.settings?.dataCollection?.latestEduBranches &&
                formik.errors.settings?.dataCollection?.latestEduBranches && (
                  <Typography variant="caption" color="error">
                    {formik.errors.settings.dataCollection.latestEduBranches}
                  </Typography>
                )} */}
            </Grid>
          </>
        )}
        {selectedGroups.includes("group_ID") && (
          <>
            <Grid item xs={8}>
              <Typography className={classes.mainText}>ID Doc Type</Typography>
              <Typography className={classes.subText}>Select allowed ID document types</Typography>
            </Grid>
            <Grid item xs={4}>
              {idproofDocTypeOptions && (
                <SelectWithCheckbox
                  title={"Select ID Doc Type"}
                  options={idproofDocTypeOptions}
                  handleSelectOption={(value) => updateDataCollection("idproofDocType", value)}
                  width={"100%"}
                  checkLength={formData.dataCollection.idproofDocType?.length}
                  BgColor={formData.dataCollection.idproofDocType?.length !== 0}
                  defaultValue={formData.dataCollection.idproofDocType || []}
                  disabledCapitalize
                  handleClearAll={() => updateDataCollection("idproofDocType", [])}
                  handleSelectAll={() => updateDataCollection("idproofDocType", Object.keys(idproofDocTypeOptions))}
                />
              )}
              {/* {formik.touched.settings?.dataCollection?.idproofDocType &&
                formik.errors.settings?.dataCollection?.idproofDocType && (
                  <Typography variant="caption" color="error">
                    {formik.errors.settings.dataCollection.idproofDocType}
                  </Typography>
                )} */}
            </Grid>
          </>
        )}
      </Grid>
      {/* Add more form fields as needed */}
    </div>
  );
};

const Step3 = ({
  formData,
  setFormData,
  classes,
  selectedGroups,
  candidateSkills,
  degreeMapping,
  convertKeyValueOption,
}) => {
  const experienceMapping = {
    0: "0 Years",
    1: "1 Year",
    2: "2 Years",
    3: "3 Years",
    4: "4 Years",
    5: "5 Years",
    6: "6 Years",
    7: "7 Years",
    8: "8 Years",
    9: "9 Years",
    10: "10 Years",
  };

  const approvalMethodOptions = {
    manual: "Manual: approve manually from the application data page (recommended for Campus Recruitment)",
    auto: "Automatic : approve automatically and send invites immediately (recommended for Lateral Hiring)",
    // auto2: "Auto2: Approve automatically and send invites after the application deadline",
  };

  const experienceMappingTrans = transformOptions(experienceMapping);
  const approvalMethodOptionsTrans = transformOptions(approvalMethodOptions);

  const AntSwitch = styled(Switch)({
    width: 25,
    height: 12,
    padding: "11px !important",
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 14,
      marginLeft: 1,
      bottom: 0,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 10,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  });

  const updateShortlistingCriteria = (key, subKey, value) => {
    if (key === "approvalMethod") {
      setFormData((prevData) => ({
        ...prevData,
        shortlistingCriteria: {
          ...prevData.shortlistingCriteria,
          approvalMethod: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        shortlistingCriteria: {
          ...prevData.shortlistingCriteria,
          [key]: {
            ...prevData.shortlistingCriteria[key],
            [subKey]: value,
          },
        },
      }));
    }
  };

  const degreeMappingTrans = transformOptions(degreeMapping);

  return (
    <div>
      <Grid item xs={12} className={classes.switchContainer}>
        <Typography className={classes.heading}>Automated Shortlisting</Typography>
        <AntSwitch
          checked={formData.automatedShortlisting}
          onChange={(e) => setFormData((prevData) => ({ ...prevData, automatedShortlisting: e.target.checked }))}
        />
      </Grid>
      {formData.automatedShortlisting && (
        <Grid item xs={12} style={{ marginTop: 0, paddingTop: 0 }}>
          <Typography className={classes.subText}>
            All applications will be shortlisted.{" "}
            {(selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) &&
              "Enable one or more of the following filters for a more focused selection."}
          </Typography>
        </Grid>
      )}
      {formData.automatedShortlisting && (
        <Grid container item xs={12}>
          {formData.application.rdType !== "campusRecruitment" && selectedGroups.includes("group_resume") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.experience.on}
                    onChange={(e) => updateShortlistingCriteria("experience", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Experience</Typography>
                  <Typography className={classes.subText}>Experience is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectMenu
                  selectvalue={experienceMapping}
                  selectHandleChange={(value) => updateShortlistingCriteria("experience", "value", value.id)}
                  prevSelectedValue={experienceMappingTrans.find(
                    (option) => option.id === formData.shortlistingCriteria.experience.value
                  )}
                  disabled={!formData.shortlistingCriteria.experience.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.experience?.value &&
                            formik.errors.settings?.shortlistingCriteria?.experience?.value && (
                              <Typography variant="caption" color="error">
                                {formik.errors.settings.shortlistingCriteria.experience.value}
                              </Typography>
                            )} */}
              </Grid>
            </>
          )}
          {/* {selectedGroups.includes("group_latestEdu" || "group_resume") && ( */}
          {(selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.degree.on}
                    onChange={(e) => updateShortlistingCriteria("degree", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Degree</Typography>
                  <Typography className={classes.subText}>Degree is at least</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectMenu
                  selectvalue={degreeMapping}
                  selectHandleChange={(value) => updateShortlistingCriteria("degree", "value", value.id)}
                  prevSelectedValue={degreeMappingTrans.find(
                    (option) => option.id === formData.shortlistingCriteria.degree.value
                  )}
                  disabled={!formData.shortlistingCriteria.degree.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.degree?.value &&
                          formik.errors.settings?.shortlistingCriteria?.degree?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.degree.value}
                            </Typography>
                          )} */}
              </Grid>
            </>
          )}
          {selectedGroups.includes("group_latestEdu") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.branch.on}
                    onChange={(e) => updateShortlistingCriteria("branch", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Branch</Typography>
                  <Typography className={classes.subText}>Branch is any one of</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} sx={{ marginTop: "10px" }}>
                <SelectWithCheckbox
                  title={"Select Branches"}
                  options={convertKeyValueOption(formData?.dataCollection.latestEduBranches)}
                  handleSelectOption={(value) => updateShortlistingCriteria("branch", "value", value)}
                  width={"100%"}
                  checkLength={formData.shortlistingCriteria.branch.value?.length}
                  BgColor={formData.shortlistingCriteria.branch.value?.length !== 0}
                  defaultValue={formData.shortlistingCriteria.branch.value || []}
                  disabledCapitalize
                  handleClearAll={() => updateShortlistingCriteria("branch", "value", [])}
                  handleSelectAll={() =>
                    updateShortlistingCriteria("branch", "value", formData?.dataCollection.latestEduBranches)
                  }
                  disabled={!formData.shortlistingCriteria.branch.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.branch?.value &&
                          formik.errors.settings?.shortlistingCriteria?.branch?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.branch.value}
                            </Typography>
                          )} */}
              </Grid>

              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.latestEduScore.on}
                    onChange={(e) => updateShortlistingCriteria("latestEduScore", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Latest Education Score</Typography>
                  <Typography className={classes.subText}>CPI/GPA/Percentage is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="latestEduScore"
                  name="Latest Education Score"
                  type="number"
                  value={formData.shortlistingCriteria.latestEduScore.value}
                  onChange={(e) => updateShortlistingCriteria("latestEduScore", "value", e.target.value)}
                  className={formData.shortlistingCriteria.latestEduScore.value === "" ? classes.field : classes.field1}
                  disabled={!formData.shortlistingCriteria.latestEduScore.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.latestEduScore?.value &&
                          formik.errors.settings?.shortlistingCriteria?.latestEduScore?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.latestEduScore.value}
                            </Typography>
                          )} */}
              </Grid>
            </>
          )}
          {selectedGroups.includes("group_resume") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.skillMatchScore.on}
                    onChange={(e) => updateShortlistingCriteria("skillMatchScore", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Skill Match Score</Typography>
                  <Typography className={classes.subText}>Skill Match Score is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="skillMatchScore"
                  name="Skill Match Score"
                  type="number"
                  value={formData.shortlistingCriteria.skillMatchScore.value}
                  onChange={(e) => updateShortlistingCriteria("skillMatchScore", "value", e.target.value)}
                  className={
                    formData.shortlistingCriteria.skillMatchScore.value === "" ? classes.field : classes.field1
                  }
                  disabled={!formData.shortlistingCriteria.skillMatchScore.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.skillMatchScore?.value &&
                          formik.errors.settings?.shortlistingCriteria?.skillMatchScore?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.skillMatchScore.value}
                            </Typography>
                          )} */}
              </Grid>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={formData.shortlistingCriteria.skill.on}
                    onChange={(e) => updateShortlistingCriteria("skill", "on", e.target.checked)}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Skills</Typography>
                  <Typography className={classes.subText}>Has all the selected skills</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  id="skills"
                  options={candidateSkills}
                  getOptionLabel={(option) => option.fullName}
                  onChange={(event, value) =>
                    updateShortlistingCriteria(
                      "skill",
                      "value",
                      value.map((skill) => skill.id)
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skills"
                      placeholder="Select skills"
                      sx={{ mt: 1 }}
                    />
                  )}
                  value={candidateSkills.filter((skill) =>
                    formData.shortlistingCriteria.skill.value.includes(skill.id)
                  )}
                  disabled={!formData.shortlistingCriteria.skill.on}
                />
                {/* {formik.touched.settings?.shortlistingCriteria?.skill?.value &&
                          formik.errors.settings?.shortlistingCriteria?.skill?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.skill.value}
                            </Typography>
                          )} */}
              </Grid>
            </>
          )}
          <Grid item xs={8} sx={{ mt: 2 }}>
            <Typography className={classes.mainText}>Approve Shortlistings</Typography>
            <Typography className={classes.subText}>
              Test invites are sent to candidates automatically after approval. This is irreversible.
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ mt: 2 }}>
            <SelectMenu
              selectvalue={approvalMethodOptions}
              selectHandleChange={(value) => updateShortlistingCriteria("approvalMethod", null, value.id)}
              prevSelectedValue={approvalMethodOptionsTrans.find(
                (option) => option.id === formData.shortlistingCriteria.approvalMethod
              )}
              // disabled={!formik.values.settings.shortlistingCriteria.approvalMethod}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const Step4 = ({ formData, setFormData, classes }) => {
  const AntSwitch = styled(Switch)({
    width: 25,
    height: 12,
    padding: "11px !important",
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 14,
      marginLeft: 1,
      bottom: 0,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 10,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  });

  const CustomDateTimePicker = ({ label, value, onChange, minDateTime }) => {
    const handleDateChange = (date) => {
      if (date) {
        date.setSeconds(0, 0); // Round seconds to 00
      }
      onChange(date);
    };
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          slotProps={{
            textField: {
              style: { width: "100%" },
              InputLabelProps: {
                shrink: false,
                style: { display: "none" }, // Hide the label
              },
              inputProps: {
                disabled: true,
              },
            },
          }}
          label={label}
          value={value}
          onAccept={handleDateChange}
          format="dd MMM yyyy hh:mm a"
          minDateTime={minDateTime}
          fullWidth
          closeOnSelect={false}
        />
      </LocalizationProvider>
    );
  };

  const updateApplicationTiming = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      application: {
        ...prevData.application,
        [key]: value,
      },
    }));
  };

  const { startDate, deadline, testStartDate, testDeadline } = formData.application;

  // Validation checks
  const isStartDeadlineValid = startDate && deadline ? new Date(deadline) > new Date(startDate) : true;
  const isTestDatesValid = testStartDate && testDeadline ? new Date(testDeadline) > new Date(testStartDate) : true;
  let maxDate = testStartDate ? Math.max(deadline, testStartDate) : deadline;
  maxDate = maxDate + 60 * 60 * 1000;
  const isTestDeadlineValid = testDeadline && maxDate ? new Date(testDeadline) > new Date(maxDate) : true;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.heading}>Application Timing</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.switchContainer}>
          <Grid>
            <AntSwitch
              checked={!formData.application.startDate}
              onChange={(e) => updateApplicationTiming("startDate", e.target.checked ? null : new Date())}
            />
          </Grid>
          <Grid>
            <Typography className={classes.mainText}>Start accepting applications immediately</Typography>
          </Grid>
        </Grid>
        {formData.application.startDate && (
          <>
            <Grid item xs={8}>
              <Typography className={classes.mainText}>Application Start Date</Typography>
              <Typography className={classes.subText}>
                Candidates will be able to submit application after this date
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CustomDateTimePicker
                label="Application Start Date"
                value={formData.application.startDate}
                onChange={(value) => updateApplicationTiming("startDate", value)}
                minDateTime={new Date()}
              />
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          <Typography className={classes.mainText}>Application Deadline</Typography>
          <Typography className={classes.subText}>
            Candidates will not be able to submit applications after this date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomDateTimePicker
            label="Application Deadline"
            value={formData.application.deadline}
            onChange={(value) => updateApplicationTiming("deadline", value)}
            minDateTime={new Date()}
          />
        </Grid>
        {!isStartDeadlineValid && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error">
              Application deadline must be after the application start date.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.heading}>Test Timing</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.switchContainer}>
          <Grid>
            <AntSwitch
              checked={!formData.application.testStartDate}
              onChange={(e) => updateApplicationTiming("testStartDate", e.target.checked ? null : new Date())}
            />
          </Grid>
          <Grid>
            <Typography className={classes.mainText}>
              Candidate can start the test immediately after receiving the test invite
            </Typography>
          </Grid>
        </Grid>
        {formData.application.testStartDate && (
          <>
            <Grid item xs={8}>
              <Typography className={classes.mainText}>Test Start Date and Time</Typography>
              <Typography className={classes.subText}>
                Candidates will be able to start the test after this date
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CustomDateTimePicker
                label="Test Start Date"
                value={formData.application.testStartDate}
                onChange={(value) => updateApplicationTiming("testStartDate", value)}
                minDateTime={new Date()}
              />
            </Grid>
          </>
        )}
        <Grid item xs={8}>
          <Typography className={classes.mainText}>Test Deadline</Typography>
          <Typography className={classes.subText}>
            Candidates will not be able to submit the test after this date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <CustomDateTimePicker
            label="Test Deadline"
            value={formData.application.testDeadline}
            onChange={(value) => updateApplicationTiming("testDeadline", value)}
            minDateTime={new Date()}
          />
        </Grid>
        {!isTestDatesValid && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error">
              Test deadline must be after the test start date.
            </Typography>
          </Grid>
        )}
        {!isTestDeadlineValid && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error">
              Test deadline must be at least 1 hour after the application deadline and the test start date.
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PublishSettingsWizard;
