import roleTypes from "../types/role";
// import { config } from "../../config";
// import authTypes from "../types/auth";

export const initialState = {
  rolelist: [],
  invitelist: [],
  isroleLoading: false,
  referencelist: [],
  addroles: [],
  editrole: [],
  deleterole: {},
  addinvite: [],
  editinvite: [],
  deleteinvite: [],
  sendinvite: [],
  expireDate: [],
  roledetails: [],
  roledetailsFailure: null,
  candidateinvitelist: [],
  generatedate: [],
  invitecandidatedetails: [],
  detailsinvite: [],
  roleCategories: {
    architect: "R&D / Architect",
    development: "Engineering / Development",
    other: "Other (IT, Support, DevOps, QE)",
  },
  rolefilter: {
    createdBy: [],
    category: ["Engineering / Development", "R&D / Architect", "Other (IT, Support, DevOps, QE)"],
    skills: [],
    status: ["active", "Draft", "inactive"],
    codingLang: ["C", "C#", "C++", "Golang", "Java", "JavaScript", "Kotlin", "Python", "R", "Scala", "Swift"],
  },
  rolestatus: [],
  roletotalcount: [],
  rolemycount: [],
  roleallcount: [],
  invitefilter: {
    invitedBy: [],
    status: ["active", "inactive"],
    jobRoleCategory: ["Engineering / Development", "R&D / Architect", "Other (IT, Support, DevOps, QE)"],
    skills: [],
  },
  invitedetails: [],
  invitedetailsFailure: null,
  candidateDashboardDetails: [],
  inviteListTotal: [],
  inviteUserTotal: [],
  inviteAllTotal: [],
  generateskill: [],
  invitestatus: [],
  rolematching: [],
  resetTest: [],
  generatedProfileData: {},
  downloadcr: {},
  downloadReportLink: "",
  createConfig: null,
  createConfigSuccess: null,
  createConfigFailure: null,
  updateConfig: null,
  updateConfigSuccess: null,
  updateConfigFailure: null,
  config: null,
  configSuccess: null,
  configFailure: null,
  skillsConfig: {},
  jobRoleData: {},
  jobRoleDataSuccess: {},
  jobRoleDataFailure: {},
  getRoleFeatures: {},
  getRoleFeaturesSuccess: {},
  getRoleFeaturesFailure: {},
  getRoleSkills: {},
  getRoleSkillsSuccess: {},
  getRoleSkillsFailure: {},
  getBehavioralSkills: {},
  getBehavioralSkillsSuccess: {},
  getBehavioralSkillsFailure: {},
  getCandidateJobRoleProfileToken: {},
  getCandidateJobRoleProfileTokenSuccess: {},
  getCandidateJobRoleProfileTokenFailure: {},
  editApplyLink: null,
  editApplyLinkSuccess: null,
  editApplyLinkFailure: null,
  createApplyLink: null,
  createApplyLinkSuccess: null,
  createApplyLinkFailure: null,
  listApplyLinks: null,
  listApplyLinksSuccess: null,
  listApplyLinksFailure: null,
  uploadApplyLinkBanner: null,
  uploadApplyLinkBannerSuccess: null,
  uploadApplyLinkBannerFailure: null,
  saveApplyLinksDetails: null,
  saveApplyLinksDetailsSuccess: null,
  saveApplyLinksDetailsFailure: null,
  publishApplyLink: null,
  publishApplyLinkSuccess: null,
  publishApplyLinkFailure: null,
  saveSettings: null,
  saveSettingsSuccess: null,
  saveSettingsFailure: null,
  appliedCandidatesList: null,
  appliedCandidatesListSuccess: null,
  appliedCandidatesListFailure: null,
  shortlistingInfo: null,
  shortlistingInfoSuccess: null,
  shortlistingInfoFailure: null,
  approveShortlisting: null,
  approveShortlistingSuccess: null,
  approveShortlistingFailure: null,
  manualShortlisting: null,
  manualShortlistingSuccess: null,
  manualShortlistingFailure: null,
  updateApplicationStatus: null,
  updateApplicationStatusSuccess: null,
  updateApplicationStatusFailure: null,
  downloadApplicationData: null,
  downloadApplicationDataSuccess: null,
  downloadApplicationDataFailure: null,
  getSettings: null,
  getSettingsSuccess: null,
  getSettingsFailure: null,
  saveSettingsAndPublish: null,
  saveSettingsAndPublishSuccess: null,
  saveSettingsAndPublishFailure: null,
  updatePurpose: null,
  updatePurposeSuccess: null,
  updatePurposeFailure: null,
};

const dummySettings = {
  application: {
    purpose: "",
    rdType: "campusRecruitment",
    startDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    deadline: null,
    testStartDate: null,
    testDeadline: null,
  },
  dataCollection: {
    collectPhone: "no",
    allowedEmailDomains: [],
    isLatestEdu: false,
    isDocumnet: false,
    latestEduScoreScale: "flexible",
    latestEduBranches: [],
    idproofDocType: [],
  },
  automatedShortlisting: false,
  shortlistingCriteria: {
    experience: {
      on: false,
      value: "0",
    },
    degree: {
      on: false,
      value: "Bachelors",
    },
    branch: {
      on: false,
      value: [],
    },
    latestEduScore: {
      on: false,
      value: "",
    },
    skillMatchScore: {
      on: false,
      value: "",
    },
    skill: {
      on: false,
      value: [],
    },
    approvalMethod: "manual",
  },
};

function role(state = initialState, action) {
  switch (action.type) {
    case roleTypes.ROLE_LIST:
      return {
        ...state,
        isauthLoading: true,
        isroleLoading: true,
        // rolelist: action.payload,
      };
    case roleTypes.ROLE_LIST_SUCCESS:
      let rolefilter = { ...state.rolefilter };

      let skillsArray = action.payload.reduce((acc, val) => {
        if (val.skills && Object.hasOwn(val, "selectedSkills")) {
          acc = [...new Set([...acc, ...val.skills])];
        }
        return acc.sort();
      }, []);

      rolefilter["selectedSkills"] = [...new Set(skillsArray.map((i) => i.toLowerCase().trim())), "unknown"];

      let createdBy = action.payload.reduce((acc, val) => {
        if (val.createdBy && Object.hasOwn(val, "createdBy")) {
          if (acc.findIndex((i) => i.displayName.toLowerCase() === val.createdBy.displayName.toLowerCase()) === -1) {
            acc = [...acc, val.createdBy];
          }
        }
        return acc.sort();
      }, []);

      rolefilter["createdBy"] = createdBy;
      return {
        ...state,
        isroleLoading: false,
        rolelist: action.payload,
        addroles: [],
        editrole: [],
        deleterole: {},
        rolefilter,
        generatedate: [],
        rolestatus: [],
        rolematching: [],
        generatedProfileData: {},
        roledetails: [],
        addinvite: [],
        generateskill: [],
      };
    case roleTypes.ROLE_LIST_RESET:
      return {
        ...state,
        isroleLoading: false,
        rolelist: [],
      };
    case roleTypes.ROLE_TOTAL_COUNT:
      return {
        ...state,
        isroleLoading: false,
        roletotalcount: action.payload,
      };
    case roleTypes.ROLE_MY_COUNT:
      return {
        ...state,
        isroleLoading: false,
        rolemycount: action.payload,
      };
    case roleTypes.ROLE_ALL_COUNT:
      return {
        ...state,
        isroleLoading: false,
        roleallcount: action.payload,
      };
    case roleTypes.ROLE_DETAILS:
      return {
        ...state,
        isroleLoading: true,
        roledetails: action.payload,
      };
    case roleTypes.ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        roledetails: action.payload,
        addroles: [],
        rolestatus: [],
        editrole: [],
        rolematching: [],
      };
    case roleTypes.ROLE_DETAILS_FAILURE:
      return {
        ...state,
        isroleLoading: false,
        roledetailsFailure: action.payload,
      };

    case roleTypes.REFERENCE_DATA:
      return {
        ...state,
        isroleLoading: true,
        referencelist: action.payload,
      };
    case roleTypes.REFERENCE_DATA_SUCCESS:
      // console.log(action.payload)
      return {
        ...state,
        isroleLoading: false,
        referencelist: action.payload,
      };
    case roleTypes.INVITE_LIST:
      return {
        ...state,
        isroleLoading: true,
        invitelist: action.payload,
        invitedetails: [],
      };
    case roleTypes.INVITE_LIST_SUCCESS:
      let invitefilter = { ...state.invitefilter };

      /*
            FK cleanup comments july 2023
            let inviteSkillsArray = action.payload.reduce((acc, val) => {
                if (val.jobRoleSkill && Object.hasOwn(val, 'jobRoleSkill')) {
                    acc = [...new Set([...acc, ...val.jobRoleSkill])]
                }
                return acc;
            }, [])
            let newArray = [...new Set(inviteSkillsArray.map(i => i.skill.toLowerCase().trim()))];
            invitefilter['skills'] = newArray.sort();
            */

      invitefilter["skills"] = [...new Set([...invitefilter["skills"]])];

      let invitedby = action.payload;
      let displayNameArray = [...new Set(invitedby.map((i) => i.inviteCreatedBy.toLowerCase().trim()))];
      invitefilter["invitedBy"] = displayNameArray.sort();

      // Assume propotionalScore -1 if not exists or TEst taken count is 0
      action.payload.map((i) => {
        if (!Object.hasOwn(i, "proportionalScore") || (i.testsTakenCount === 0 && i.proportionalScore === 0)) {
          i["proportionalScore"] = -1;
        }
        return i;
      });

      return {
        ...state,
        isroleLoading: false,
        invitelist: action.payload,
        addinvite: [],
        editinvite: [],
        sendinvite: [],
        expireDate: [],
        inviteListTotal: action.payload,
        invitefilter,
        invitedetails: [],
      };

    case roleTypes.INVITE_TOTAL_COUNT:
      return {
        ...state,
        isroleLoading: false,
        inviteListTotal: action.payload,
      };
    case roleTypes.INVITE_MY_COUNT:
      return {
        ...state,
        isroleLoading: false,
        inviteUserTotal: action.payload,
      };
    case roleTypes.INVITE_ALL_COUNT:
      return {
        ...state,
        isroleLoading: false,
        inviteAllTotal: action.payload,
      };
    // case roleTypes.INVITE_ALL_COUNT:
    //     return {
    //         ...state,
    //         isroleLoading: false,
    //         inviteAllTotal: action.payload
    //     };
    case roleTypes.CANDIDATE_INVITE:
      return {
        ...state,
        isroleLoading: true,
        candidateinvitelist: action.payload,
        invitedetails: [],
      };
    case roleTypes.CANDIDATE_INVITE_SUCCESS:
      action.payload.map((i) => {
        i["roleId"] = i.jobRoleId; //i.jobRole.uid;
        i["roleName"] = i.jobRoleName; //i.jobRole.name;
        i["targetScore"] = i.jobRoleTargetScore; //i.jobRole.targetScore;
        i["score"] = i.proportionalScore; //i.jobRole.proportionalScore;
        i["newFirstName"] = i.candidate.user.firstName;
        i["newLastName"] = i.candidate.user.lastName;

        return i;
      });

      return {
        ...state,
        isroleLoading: false,
        candidateinvitelist: action.payload,
        addinvite: [],
        editinvite: [],
        sendinvite: [],
        expireDate: [],
        invitestatus: [],
        resetTest: [],
        invitedetails: [],
        downloadcr: {},
      };
    case roleTypes.INVITE_LIST_RESET:
      return {
        ...state,
        isroleLoading: false,
        candidateinvitelist: null,
      };
    case roleTypes.ROLE_STATUS:
      return {
        ...state,
        isroleLoading: true,
        rolestatus: action.payload,
      };
    case roleTypes.ROLE_STATUS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        rolestatus: action.payload,
      };
    case roleTypes.ROLE_GENERATE_SKILL:
      return {
        ...state,
        isroleLoading: true,
        generateskill: action.payload,
      };
    case roleTypes.ROLE_GENERATE_SKILL_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        generateskill: action.payload,
      };
    case roleTypes.ADD_ROLE:
      return {
        ...state,
        isroleLoading: true,
        addroles: action.payload,
      };
    case roleTypes.ADD_ROLE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        addroles: action.payload,
        rolematching: [],
      };
    case roleTypes.UPDATE_ROLE:
      return {
        ...state,
        isroleLoading: true,
        editrole: action.payload,
      };
    case roleTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        editrole: action.payload,
      };
    case roleTypes.DELETE_ROLE:
      return {
        ...state,
        isroleLoading: true,
        deleterole: action.payload,
      };
    case roleTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        deleterole: action.payload,
      };
    case roleTypes.ADD_INVITE:
      return {
        ...state,
        isLoading: true,
        addinvite: action.payload,
      };
    case roleTypes.ADD_INVITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addinvite: action.payload,
      };
    case roleTypes.UPDATE_INVITE:
      return {
        ...state,
        isroleLoading: true,
        editinvite: action.payload,
      };
    case roleTypes.UPDATE_INVITE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        editinvite: action.payload,
      };
    case roleTypes.SEND_INVTE_REMINDER:
      return {
        ...state,
        isroleLoading: true,
        sendinvite: action.payload,
      };
    case roleTypes.SEND_INVTE_REMINDER_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        sendinvite: action.payload,
      };
    case roleTypes.EXTEND_EXPIRATION_DATA:
      return {
        ...state,
        isroleLoading: true,
        expireDate: action.payload,
      };
    case roleTypes.EXTEND_EXPIRATION_DATA_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        expireDate: action.payload,
        invitedetails: action.payload.data,
      };
    case roleTypes.EXTEND_EXPIRATION_RESET:
      return {
        ...state,
        isroleLoading: false,
        expireDate: null,
      };
    case roleTypes.GENERATE_PROFILE:
      return {
        ...state,
        isroleLoading: true,
        generatedProfileData: action.payload,
      };
    case roleTypes.GENERATE_PROFILE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        generatedProfileData: action.payload,
      };
    case roleTypes.INVITE_CANDIDATE_DETAILS:
      return {
        ...state,
        isroleLoading: true,
        invitecandidatedetails: action.payload,
      };
    case roleTypes.INVITE_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        invitecandidatedetails: action.payload,
      };
    case roleTypes.DETAIL_INVITE_CANDIDATE:
      return {
        ...state,
        isroleLoading: true,
        detailsinvite: action.payload,
      };
    case roleTypes.DETAIL_INVITE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        detailsinvite: action.payload,
      };
    case roleTypes.FILTER_ROLE:
      return {
        ...state,
        isroleLoading: true,
        rolefilter: action.payload,
      };
    case roleTypes.FILTER_ROLE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        rolefilter: action.payload,
      };
    case roleTypes.INVITE_FILTER:
      return {
        ...state,
        isroleLoading: true,
        invitefilter: action.payload,
      };
    case roleTypes.INVITE_FILTER_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        invitefilter: action.payload,
      };
    case roleTypes.INVITE_DETAILS:
      return {
        ...state,
        isroleLoading: true,
        invitedetails: action.payload,
      };
    case roleTypes.INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        invitedetails: action.payload,
        candidateDashboardDetails: action.payload,
        invitestatus: [],
        resetTest: [],
        expireDate: [],
        downloadcr: {},
      };
    case roleTypes.INVITE_DETAILS_FAILURE:
      return {
        ...state,
        isroleLoading: false,
        invitedetailsFailure: action.payload,
      };
    case roleTypes.CANDIDATE_DASHBOARD_DETAILS:
      return {
        ...state,
        isroleLoading: true,
        candidateDashboardDetails: action.payload,
      };
    case roleTypes.CANDIDATE_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        candidateDashboardDetails: action.payload,
      };
    case roleTypes.INVITE_STATUS:
      return {
        ...state,
        isroleLoading: true,
        invitestatus: action.payload,
      };
    case roleTypes.INVITE_STATUS_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        invitestatus: action.payload,
      };
    case roleTypes.ROLESKILL_MATCHING:
      return {
        ...state,
        isroleLoading: true,
        rolematching: action.payload,
      };
    case roleTypes.ROLESKILL_MATCHING_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        rolematching: action.payload,
      };
    case roleTypes.RESET_TEST:
      return {
        ...state,
        isroleLoading: true,
        resetTest: action.payload,
      };
    case roleTypes.RESET_TEST_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        resetTest: action.payload,
      };
    case roleTypes.DOWNLOAD_CR:
      return {
        ...state,
        isroleLoading: true,
        downloadcr: action.payload,
      };
    case roleTypes.DOWNLOAD_CR_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        downloadcr: action.payload,
      };
    case roleTypes.DOWNLOAD_REPORT_STATUS:
      return {
        ...state,
        isroleLoading: false,
        downloadcr: null,
      };
    case roleTypes.UPDATE_INVITE_PARAMS_SUCCESS:
      return {
        ...state,
        downloadReportLink: action.payload.reportLink,
      };

    case roleTypes.CREATE_CONFIG:
      return { ...state, isauthLoading: true, createConfig: action.payload };

    case roleTypes.CREATE_CONFIG_SUCCESS:
      return { ...state, isauthLoading: false, createConfigSuccess: action.payload };

    case roleTypes.CREATE_CONFIG_FAILURE:
      return { ...state, isauthLoading: false, createConfigFailure: action.payload };

    case roleTypes.UPDATE_CONFIG:
      return { ...state, isauthLoading: true, updateConfig: action.payload };

    case roleTypes.UPDATE_CONFIG_SUCCESS:
      return { ...state, isauthLoading: false, updateConfigSuccess: action.payload };

    case roleTypes.UPDATE_CONFIG_FAILURE:
      return { ...state, isauthLoading: false, updateConfigFailure: action.payload };

    case roleTypes.CONFIG:
      return { ...state, isauthLoading: true, config: action.payload };

    case roleTypes.CONFIG_SUCCESS:
      return { ...state, isauthLoading: false, configSuccess: action.payload };

    case roleTypes.CONFIG_FAILURE:
      return { ...state, isauthLoading: false, configFailure: action.payload };

    case roleTypes.JOB_ROLE_DATA:
      return { ...state, isauthLoading: true, jobRoleData: action.payload };

    case roleTypes.JOB_ROLE_DATA_SUCCESS:
      return { ...state, isauthLoading: false, jobRoleDataSuccess: action.payload };

    case roleTypes.JOB_ROLE_DATA_FAILURE:
      return { ...state, isauthLoading: false, jobRoleDataFailure: action.payload };

    case roleTypes.GET_ROLE_FEATURES:
      return { ...state, isauthLoading: true, getRoleFeatures: action.payload };

    case roleTypes.GET_ROLE_FEATURES_SUCCESS:
      return { ...state, isauthLoading: false, getRoleFeaturesSuccess: action.payload };

    case roleTypes.GET_ROLE_FEATURES_FAILURE:
      return { ...state, isauthLoading: false, getRoleFeaturesFailure: action.payload };

    case roleTypes.GET_ROLE_SKILLS:
      return { ...state, isauthLoading: true, getRoleSkills: action.payload };

    case roleTypes.GET_ROLE_SKILLS_SUCCESS:
      return { ...state, isauthLoading: false, getRoleSkillsSuccess: action.payload };

    case roleTypes.GET_ROLE_SKILLS_FAILURE:
      return { ...state, isauthLoading: false, getRoleSkillsFailure: action.payload };

    case roleTypes.GET_BEHAVIORAL_SKILLS:
      return { ...state, isauthLoading: true, getBehavioralSkills: action.payload };

    case roleTypes.GET_BEHAVIORAL_SKILLS_SUCCESS:
      return { ...state, isauthLoading: false, getBehavioralSkillsSuccess: action.payload };

    case roleTypes.GET_BEHAVIORAL_SKILLS_FAILURE:
      return { ...state, isauthLoading: false, getBehavioralSkillsFailure: action.payload };

    case roleTypes.SET_SKILLS_CONFIG:
      // Set RoleFilter and InviterFilter Skills
      let skills = [];
      let codingLangs = [];

      for (let skill in action.payload) {
        if (action.payload[skill].isRoleSkill) {
          // skills.push(action.payload[skill].fullName)
          skills.push({ id: skill, displayName: action.payload[skill].fullName });
        }

        if (action.payload[skill].isLanguageSkill) {
          // codingLangs.push(action.payload[skill].fullName)
          codingLangs.push({ id: skill, displayName: action.payload[skill].fullName });
        }
      }

      // skills = [...new Set(skills.map(i=>i.toLowerCase().trim()))]

      const rolefilters = state.rolefilter;
      const invitefilters = state.invitefilter;
      rolefilters.skills = [...skills.sort((a, b) => a.displayName.localeCompare(b.displayName))];
      rolefilters.codingLang = [
        ...codingLangs.sort((a, b) => a.displayName.localeCompare(b.displayName)),
        { id: "Not Required", displayName: "Not Required" },
      ];
      invitefilters.skills = [...skills.sort((a, b) => a.displayName.localeCompare(b.displayName))];

      return { ...state, skillsConfig: action.payload, rolefilter: rolefilters, invitefilter: invitefilters };

    case roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN:
      return { ...state, isauthLoading: true, getCandidateJobRoleProfileToken: action.payload };

    case roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_SUCCESS:
      return { ...state, isauthLoading: false, getCandidateJobRoleProfileTokenSuccess: action.payload };

    case roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_FAILURE:
      return { ...state, isauthLoading: false, getCandidateJobRoleProfileTokenFailure: action.payload };

    case roleTypes.EDIT_APPLY_LINK:
      return { ...state, isroleLoading: true, editApplyLink: action.payload };

    // case roleTypes.EDIT_APPLY_LINK_SUCCESS:
    //   return { ...state, isroleLoading: false, editApplyLinkSuccess: action.payload };

    case roleTypes.EDIT_APPLY_LINK_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        editApplyLinkSuccess: {
          ...action.payload,
          draft: {
            ...action.payload.draft,
            settings: action.payload.draft.settings || dummySettings,
          },
        },
      };

    case roleTypes.EDIT_APPLY_LINK_FAILURE:
      return { ...state, isroleLoading: false, editApplyLinkFailure: action.payload };

    case roleTypes.CREATE_APPLY_LINK:
      return { ...state, isroleLoading: true, createApplyLink: action.payload };

    case roleTypes.CREATE_APPLY_LINK_SUCCESS:
      return { ...state, isroleLoading: false, createApplyLinkSuccess: action.payload };

    case roleTypes.CREATE_APPLY_LINK_FAILURE:
      return { ...state, isroleLoading: false, createApplyLinkFailure: action.payload };

    case roleTypes.CLEAR_CREATE_APPLY_LINK_SUCCESS:
      return { ...state, createApplyLinkSuccess: null };

    case roleTypes.LIST_APPLY_LINKS:
      return { ...state, isroleLoading: true, listApplyLinks: action.payload };

    case roleTypes.LIST_APPLY_LINKS_SUCCESS:
      return { ...state, isroleLoading: false, listApplyLinksSuccess: action.payload };

    case roleTypes.LIST_APPLY_LINKS_FAILURE:
      return { ...state, isroleLoading: false, listApplyLinksFailure: action.payload };

    case roleTypes.UPLOAD_APPLY_LINK_BANNER:
      return { ...state, isroleLoading: true, uploadApplyLinkBanner: action.payload };

    case roleTypes.UPLOAD_APPLY_LINK_BANNER_SUCCESS:
      return { ...state, isroleLoading: false, uploadApplyLinkBannerSuccess: action.payload };

    case roleTypes.UPLOAD_APPLY_LINK_BANNER_FAILURE:
      return { ...state, isroleLoading: false, uploadApplyLinkBannerFailure: action.payload };

    case roleTypes.CLEAR_UPLOAD_APPLY_LINK_BANNER_SUCCESS:
      return { ...state, uploadApplyLinkBannerSuccess: null };

    case roleTypes.SAVE_APPLY_LINKS_DETAILS:
      return { ...state, isroleLoading: true, saveApplyLinksDetails: action.payload };

    case roleTypes.SAVE_APPLY_LINKS_DETAILS_SUCCESS:
      return { ...state, isroleLoading: false, saveApplyLinksDetailsSuccess: action.payload };

    case roleTypes.SAVE_APPLY_LINKS_DETAILS_FAILURE:
      return { ...state, isroleLoading: false, saveApplyLinksDetailsFailure: action.payload };

    case roleTypes.CLEAR_PUBLISH_APPLY_LINK_SUCCESS:
      return { ...state, publishApplyLinkSuccess: null };

    case roleTypes.PUBLISH_APPLY_LINK:
      return { ...state, isroleLoading: true, publishApplyLink: action.payload };

    case roleTypes.PUBLISH_APPLY_LINK_SUCCESS:
      return { ...state, isroleLoading: false, publishApplyLinkSuccess: action.payload };

    case roleTypes.PUBLISH_APPLY_LINK_FAILURE:
      return { ...state, isroleLoading: false, publishApplyLinkFailure: action.payload };

    case roleTypes.SAVE_SETTINGS:
      return { ...state, isroleLoading: true, saveSettings: action.payload };

    case roleTypes.SAVE_SETTINGS_SUCCESS:
      return { ...state, isroleLoading: false, saveSettingsSuccess: action.payload };

    case roleTypes.SAVE_SETTINGS_FAILURE:
      return { ...state, isroleLoading: false, saveSettingsFailure: action.payload };

    case roleTypes.APPLIED_CANDIDATES_LIST:
      return { ...state, isroleLoading: true, appliedCandidatesList: action.payload };

    case roleTypes.APPLIED_CANDIDATES_LIST_SUCCESS:
      return { ...state, isroleLoading: false, appliedCandidatesListSuccess: action.payload };

    case roleTypes.APPLIED_CANDIDATES_LIST_FAILURE:
      return { ...state, isroleLoading: false, appliedCandidatesListFailure: action.payload };

    case roleTypes.SHORTLISTING_INFO:
      return { ...state, isroleLoading: true, shortlistingInfo: action.payload };

    case roleTypes.SHORTLISTING_INFO_SUCCESS:
      return { ...state, isroleLoading: false, shortlistingInfoSuccess: action.payload };

    case roleTypes.SHORTLISTING_INFO_FAILURE:
      return { ...state, isroleLoading: false, shortlistingInfoFailure: action.payload };

    case roleTypes.CLEAR_APPROVE_SHORTLISTING_SUCCESS:
      return { ...state, approveShortlistingSuccess: null };

    case roleTypes.APPROVE_SHORTLISTING:
      return { ...state, isroleLoading: true, approveShortlisting: action.payload };

    case roleTypes.APPROVE_SHORTLISTING_SUCCESS:
      return { ...state, isroleLoading: false, approveShortlistingSuccess: action.payload };

    case roleTypes.APPROVE_SHORTLISTING_FAILURE:
      return { ...state, isroleLoading: false, approveShortlistingFailure: action.payload };

    case roleTypes.CLEAR_MANUAL_SHORTLISTING_SUCCESS:
      return { ...state, manualShortlistingSuccess: null };

    case roleTypes.MANUAL_SHORTLISTING:
      return { ...state, isroleLoading: true, manualShortlisting: action.payload };

    // case roleTypes.MANUAL_SHORTLISTING_SUCCESS:
    //   return { ...state, isroleLoading: false, manualShortlistingSuccess: action.payload };

    case roleTypes.MANUAL_SHORTLISTING_SUCCESS:
      const updatedCandidates = action.payload;
      const updatedCandidatesListManual = state.appliedCandidatesListSuccess.data.map((candidate) => {
        const updatedCandidate = updatedCandidates.find((cand) => cand.applicationId === candidate.applicationId);
        return updatedCandidate ? { ...candidate, ...updatedCandidate } : candidate;
      });

      const changedCandidates = updatedCandidates.filter((updatedCand) => {
        const originalCand = state.appliedCandidatesListSuccess.data.find(
          (candidate) => candidate.applicationId === updatedCand.applicationId
        );
        return originalCand && originalCand.status !== updatedCand.status;
      });

      const changedCandidatesCount = changedCandidates.length;

      return {
        ...state,
        isroleLoading: false,
        manualShortlistingSuccess: {
          ...action.payload,
          changedCandidatesCount,
        },
        appliedCandidatesListSuccess: {
          ...state.appliedCandidatesListSuccess,
          data: updatedCandidatesListManual,
        },
      };

    case roleTypes.MANUAL_SHORTLISTING_FAILURE:
      return { ...state, isroleLoading: false, manualShortlistingFailure: action.payload };

    case roleTypes.CLEAR_UPDATE_APPLICATION_STATUS_SUCCESS:
      return { ...state, updateApplicationStatusSuccess: null };

    case roleTypes.UPDATE_APPLICATION_STATUS:
      return { ...state, isroleLoading: true, updateApplicationStatus: action.payload };

    // case roleTypes.UPDATE_APPLICATION_STATUS_SUCCESS:
    //   return { ...state, isroleLoading: false, updateApplicationStatusSuccess: action.payload };

    case roleTypes.UPDATE_APPLICATION_STATUS_SUCCESS:
      const updatedApplications = action.payload.applicationsList;
      const updatedCandidatesList = state.appliedCandidatesListSuccess.data.map((candidate) => {
        const updatedApplication = updatedApplications.find((app) => app.applicationId == candidate.applicationId);
        return updatedApplication
          ? { ...candidate, ...updatedApplication, applicationId: Number(updatedApplication.applicationId) }
          : candidate;
      });

      const changedApplications = updatedApplications.filter((updatedApp) => {
        const originalApp = state.appliedCandidatesListSuccess.data.find(
          (candidate) => candidate.applicationId == updatedApp.applicationId
        );
        return originalApp && originalApp.status != updatedApp.status;
      });

      const changedCount = changedApplications.length;

      return {
        ...state,
        isroleLoading: false,
        updateApplicationStatusSuccess: {
          ...action.payload,
          changedCount,
        },
        appliedCandidatesListSuccess: {
          ...state.appliedCandidatesListSuccess,
          data: updatedCandidatesList,
        },
      };

    case roleTypes.UPDATE_APPLICATION_STATUS_FAILURE:
      return { ...state, isroleLoading: false, updateApplicationStatusFailure: action.payload };

    case roleTypes.CLEAR_DOWNLOAD_APPLICATION_DATA_SUCCESS:
      return { ...state, downloadApplicationDataSuccess: null };

    case roleTypes.DOWNLOAD_APPLICATION_DATA:
      return { ...state, isroleLoading: true, downloadApplicationData: action.payload };

    case roleTypes.DOWNLOAD_APPLICATION_DATA_SUCCESS:
      return { ...state, isroleLoading: false, downloadApplicationDataSuccess: action.payload };

    case roleTypes.DOWNLOAD_APPLICATION_DATA_FAILURE:
      return { ...state, isroleLoading: false, downloadApplicationDataFailure: action.payload };

    case roleTypes.GET_SETTINGS:
      return { ...state, isroleLoading: true, getSettings: action.payload };

    case roleTypes.GET_SETTINGS_SUCCESS:
      return { ...state, isroleLoading: false, getSettingsSuccess: action.payload };

    case roleTypes.GET_SETTINGS_FAILURE:
      return { ...state, isroleLoading: false, getSettingsFailure: action.payload };

    case roleTypes.CLEAR_SAVE_SETTINGS_AND_PUBLISH_SUCCESS:
      return { ...state, saveSettingsAndPublishSuccess: null };

    case roleTypes.SAVE_SETTINGS_AND_PUBLISH:
      return { ...state, isroleLoading: true, saveSettingsAndPublish: action.payload };

    case roleTypes.SAVE_SETTINGS_AND_PUBLISH_SUCCESS:
      return { ...state, isroleLoading: false, saveSettingsAndPublishSuccess: action.payload };

    case roleTypes.SAVE_SETTINGS_AND_PUBLISH_FAILURE:
      return { ...state, isroleLoading: false, saveSettingsAndPublishFailure: action.payload };

    case roleTypes.CLEAR_UPDATE_PURPOSE_SUCCESS:
      return { ...state, updatePurposeSuccess: null };

    case roleTypes.UPDATE_PURPOSE:
      return { ...state, isroleLoading: false, updatePurpose: action.payload };

    case roleTypes.UPDATE_PURPOSE_SUCCESS:
      return {
        ...state,
        isroleLoading: false,
        updatePurposeSuccess: action.payload,
        listApplyLinksSuccess: state.listApplyLinksSuccess.map((link) =>
          link.linkId === action.payload.linkId ? { ...link, purpose: action.payload.purpose } : link
        ),
      };

    case roleTypes.UPDATE_PURPOSE_FAILURE:
      return { ...state, isroleLoading: false, updatePurposeFailure: action.payload };

    default:
      return { ...state };
  }
}
export default role;
