const roleTypes = {
  REFERENCE_DATA: "REFERENCE_DATA",
  REFERENCE_DATA_SUCCESS: "REFERENCE_DATA_SUCCESS",
  ROLE_LIST: "ROLE_LIST",
  ROLE_LIST_SUCCESS: "ROLE_LIST_SUCCESS",
  ROLE_LIST_RESET: "ROLE_LIST_RESET",
  ADD_ROLE: "ADD_ROLE",
  ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  INVITE_LIST: "INVITE_LIST",
  INVITE_LIST_SUCCESS: "INVITE_LIST_SUCCESS",
  INVITE_LIST_RESET: "INVITE_LIST_RESET",
  ADD_INVITE: "ADD_INVITE",
  ADD_INVITE_SUCCESS: "ADD_INVITE_SUCCESS",
  UPDATE_INVITE: "UPDATE_INVITE",
  UPDATE_INVITE_SUCCESS: "UPDATE_INVITE_SUCCESS",
  DELETE_INVITE: "DELETE_INVITE",
  DELETE_INVITE_SUCCESS: "DELETE_INVITE_SUCCESS",
  SEND_INVTE_REMINDER: "SEND_INVTE_REMINDER",
  SEND_INVTE_REMINDER_SUCCESS: "SEND_INVTE_REMINDER_SUCCESS",
  EXTEND_EXPIRATION_DATA: " EXTEND_EXPIRATION_DATA",
  EXTEND_EXPIRATION_DATA_SUCCESS: " EXTEND_EXPIRATION_DATA_SUCCESS",
  ROLE_DETAILS: "ROLE_DETAILS",
  ROLE_DETAILS_SUCCESS: "ROLE_DETAILS_SUCCESS",
  ROLE_DETAILS_FAILURE: "ROLE_DETAILS_FAILURE",
  CANDIDATE_INVITE: "CANDIDATE_INVITE",
  CANDIDATE_INVITE_SUCCESS: "CANDIDATE_INVITE_SUCCESS",
  GENERATE_PROFILE: "GENERATE_PROFILE",
  GENERATE_PROFILE_SUCCESS: "GENERATE_PROFILE_SUCCESS",
  INVITE_CANDIDATE_DETAILS: "INVITE_CANDIDATE_DETAILS",
  INVITE_CANDIDATE_DETAILS_SUCCESS: "INVITE_CANDIDATE_DETAILS_SUCCESS",
  DETAIL_INVITE_CANDIDATE: " DETAIL_INVITE_CANDIDATE",
  DETAIL_INVITE_CANDIDATE_SUCCESS: " DETAIL_INVITE_CANDIDATE_SUCCESS",
  FILTER_ROLE: "FILTER_ROLE",
  FILTER_ROLE_SUCCESS: "FILTER_ROLE_SUCCESS",
  ROLE_STATUS: "ROLE_STATUS",
  ROLE_STATUS_SUCCESS: "ROLE_STATUS_SUCCESS",
  ROLE_TOTAL_COUNT: "ROLE_TOTAL_COUNT",
  ROLE_MY_COUNT: "ROLE_MY_COUNT",
  ROLE_ALL_COUNT: "ROLE_ALL_COUNT",
  INVITE_FILTER: "INVITE_FILTER",
  INVITE_FILTER_SUCCESS: "INVITE_FILTER_SUCCESS",
  INVITE_DETAILS: "INVITE_DETAILS",
  INVITE_DETAILS_SUCCESS: "INVITE_DETAILS_SUCCESS",
  INVITE_DETAILS_FAILURE: "INVITE_DETAILS_FAILURE",
  CANDIDATE_DASHBOARD_DETAILS: "CANDIDATE_DASHBOARD_DETAILS",
  CANDIDATE_DASHBOARD_DETAILS_SUCCESS: "CANDIDATE_DASHBOARD_DETAILS_SUCCESS",
  CANDIDATE_DASHBOARD_DETAILS_FAILURE: "CANDIDATE_DASHBOARD_DETAILS_FAILURE",
  INVITE_TOTAL_COUNT: "INVITE_TOTAL_COUNT",
  INVITE_MY_COUNT: "INVITE_MY_COUNT",
  INVITE_ALL_COUNT: "INVITE_ALL_COUNT",
  ROLE_GENERATE_SKILL: "ROLE_GENERATE_SKILL",
  ROLE_GENERATE_SKILL_SUCCESS: "ROLE_GENERATE_SKILL_SUCCESS",
  INVITE_STATUS: "INVITE_STATUS",
  INVITE_STATUS_SUCCESS: "INVITE_STATUS_SUCCESS",
  ROLESKILL_MATCHING: "ROLESKILL_MATCHING",
  ROLESKILL_MATCHING_SUCCESS: "ROLESKILL_MATCHING_SUCCESS",
  RESET_TEST: "RESET_TEST",
  RESET_TEST_SUCCESS: "RESET_TEST_SUCCESS",
  DOWNLOAD_CR: "DOWNLOAD_CR",
  DOWNLOAD_CR_SUCCESS: "DOWNLOAD_CR_SUCCESS",
  EXTEND_EXPIRATION_RESET: "EXTEND_EXPIRATION_RESET",
  DOWNLOAD_REPORT_STATUS: "DOWNLOAD_REPORT_STATUS",
  // UPDATE_INVITE_PARAMS: "UPDATE_INVITE_PARAMS",
  // UPDATE_INVITE_PARAMS_SUCCESS: "UPDATE_INVITE_PARAMS_SUCCESS",
  CREATE_CONFIG: "CREATE_CONFIG",
  CREATE_CONFIG_SUCCESS: "CREATE_CONFIG_SUCCESS",
  CREATE_CONFIG_FAILURE: "CREATE_CONFIG_FAILURE",
  UPDATE_CONFIG: "UPDATE_CONFIG",
  UPDATE_CONFIG_SUCCESS: "UPDATE_CONFIG_SUCCESS",
  UPDATE_CONFIG_FAILURE: "UPDATE_CONFIG_FAILURE",
  CONFIG: "CONFIG",
  CONFIG_SUCCESS: "CONFIG_SUCCESS",
  CONFIG_FAILURE: "CONFIG_FAILURE",
  SET_SKILLS_CONFIG: "SET_SKILLS_CONFIG",
  JOB_ROLE_DATA: "JOB_ROLE_DATA",
  JOB_ROLE_DATA_SUCCESS: "JOB_ROLE_DATA_SUCCESS",
  JOB_ROLE_DATA_FAILURE: "JOB_ROLE_DATA_FAILURE",
  GET_ROLE_FEATURES: "GET_ROLE_FEATURES",
  GET_ROLE_FEATURES_SUCCESS: "GET_ROLE_FEATURES_SUCCESS",
  GET_ROLE_FEATURES_FAILURE: "GET_ROLE_FEATURES_FAILURE",
  GET_ROLE_SKILLS: "GET_ROLE_SKILLS",
  GET_ROLE_SKILLS_SUCCESS: "GET_ROLE_SKILLS_SUCCESS",
  GET_ROLE_SKILLS_FAILURE: "GET_ROLE_SKILLS_FAILURE",
  GET_BEHAVIORAL_SKILLS: "GET_BEHAVIORAL_SKILLS",
  GET_BEHAVIORAL_SKILLS_SUCCESS: "GET_BEHAVIORAL_SKILLS_SUCCESS",
  GET_BEHAVIORAL_SKILLS_FAILURE: "GET_BEHAVIORAL_SKILLS_FAILURE",
  GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN: "GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN",
  GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_SUCCESS: "GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_SUCCESS",
  GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_FAILURE: "GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN_FAILURE",
  EDIT_APPLY_LINK: "EDIT_APPLY_LINK",
  EDIT_APPLY_LINK_SUCCESS: "EDIT_APPLY_LINK_SUCCESS",
  EDIT_APPLY_LINK_FAILURE: "EDIT_APPLY_LINK_FAILURE",
  CREATE_APPLY_LINK: "CREATE_APPLY_LINK",
  CREATE_APPLY_LINK_SUCCESS: "CREATE_APPLY_LINK_SUCCESS",
  CREATE_APPLY_LINK_FAILURE: "CREATE_APPLY_LINK_FAILURE",
  CLEAR_CREATE_APPLY_LINK_SUCCESS: "CLEAR_CREATE_APPLY_LINK_SUCCESS",
  LIST_APPLY_LINKS: "LIST_APPLY_LINKS",
  LIST_APPLY_LINKS_SUCCESS: "LIST_APPLY_LINKS_SUCCESS",
  LIST_APPLY_LINKS_FAILURE: "LIST_APPLY_LINKS_FAILURE",
  UPLOAD_APPLY_LINK_BANNER: "UPLOAD_APPLY_LINK_BANNER",
  UPLOAD_APPLY_LINK_BANNER_SUCCESS: "UPLOAD_APPLY_LINK_BANNER_SUCCESS",
  UPLOAD_APPLY_LINK_BANNER_FAILURE: "UPLOAD_APPLY_LINK_BANNER_FAILURE",
  CLEAR_UPLOAD_APPLY_LINK_BANNER_SUCCESS: "CLEAR_UPLOAD_APPLY_LINK_BANNER_SUCCESS",
  SAVE_APPLY_LINKS_DETAILS: "SAVE_APPLY_LINKS_DETAILS",
  SAVE_APPLY_LINKS_DETAILS_SUCCESS: "SAVE_APPLY_LINKS_DETAILS_SUCCESS",
  SAVE_APPLY_LINKS_DETAILS_FAILURE: "SAVE_APPLY_LINKS_DETAILS_FAILURE",
  CLEAR_PUBLISH_APPLY_LINK_SUCCESS: "CLEAR_PUBLISH_APPLY_LINK_SUCCESS",
  PUBLISH_APPLY_LINK: "PUBLISH_APPLY_LINK",
  PUBLISH_APPLY_LINK_SUCCESS: "PUBLISH_APPLY_LINK_SUCCESS",
  PUBLISH_APPLY_LINK_FAILURE: "PUBLISH_APPLY_LINK_FAILURE",
  SAVE_SETTINGS: "SAVE_SETTINGS",
  SAVE_SETTINGS_SUCCESS: "SAVE_SETTINGS_SUCCESS",
  SAVE_SETTINGS_FAILURE: "SAVE_SETTINGS_FAILURE",
  APPLIED_CANDIDATES_LIST: "APPLIED_CANDIDATES_LIST",
  APPLIED_CANDIDATES_LIST_SUCCESS: "APPLIED_CANDIDATES_LIST_SUCCESS",
  APPLIED_CANDIDATES_LIST_FAILURE: "APPLIED_CANDIDATES_LIST_FAILURE",
  SHORTLISTING_INFO: "SHORTLISTING_INFO",
  SHORTLISTING_INFO_SUCCESS: "SHORTLISTING_INFO_SUCCESS",
  SHORTLISTING_INFO_FAILURE: "SHORTLISTING_INFO_FAILURE",
  CLEAR_APPROVE_SHORTLISTING_SUCCESS: "CLEAR_APPROVE_SHORTLISTING_SUCCESS",
  APPROVE_SHORTLISTING: "APPROVE_SHORTLISTING",
  APPROVE_SHORTLISTING_SUCCESS: "APPROVE_SHORTLISTING_SUCCESS",
  APPROVE_SHORTLISTING_FAILURE: "APPROVE_SHORTLISTING_FAILURE",
  CLEAR_MANUAL_SHORTLISTING_SUCCESS: "CLEAR_MANUAL_SHORTLISTING_SUCCESS",
  MANUAL_SHORTLISTING: "MANUAL_SHORTLISTING",
  MANUAL_SHORTLISTING_SUCCESS: "MANUAL_SHORTLISTING_SUCCESS",
  MANUAL_SHORTLISTING_FAILURE: "MANUAL_SHORTLISTING_FAILURE",
  CLEAR_UPDATE_APPLICATION_STATUS_SUCCESS: "CLEAR_UPDATE_APPLICATION_STATUS_SUCCESS",
  UPDATE_APPLICATION_STATUS: "UPDATE_APPLICATION_STATUS",
  UPDATE_APPLICATION_STATUS_SUCCESS: "UPDATE_APPLICATION_STATUS_SUCCESS",
  UPDATE_APPLICATION_STATUS_FAILURE: "UPDATE_APPLICATION_STATUS_FAILURE",
  CLEAR_DOWNLOAD_APPLICATION_DATA_SUCCESS: "CLEAR_DOWNLOAD_APPLICATION_DATA_SUCCESS",
  DOWNLOAD_APPLICATION_DATA: "DOWNLOAD_APPLICATION_DATA",
  DOWNLOAD_APPLICATION_DATA_SUCCESS: "DOWNLOAD_APPLICATION_DATA_SUCCESS",
  DOWNLOAD_APPLICATION_DATA_FAILURE: "DOWNLOAD_APPLICATION_DATA_FAILURE",
  GET_SETTINGS: "GET_SETTINGS",
  GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
  GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",
  CLEAR_SAVE_SETTINGS_AND_PUBLISH_SUCCESS: "CLEAR_SAVE_SETTINGS_AND_PUBLISH_SUCCESS",
  SAVE_SETTINGS_AND_PUBLISH: "SAVE_SETTINGS_AND_PUBLISH",
  SAVE_SETTINGS_AND_PUBLISH_SUCCESS: "SAVE_SETTINGS_AND_PUBLISH_SUCCESS",
  SAVE_SETTINGS_AND_PUBLISH_FAILURE: "SAVE_SETTINGS_AND_PUBLISH_FAILURE",
  CLEAR_UPDATE_PURPOSE_SUCCESS: "CLEAR_UPDATE_PURPOSE_SUCCESS",
  UPDATE_PURPOSE: "UPDATE_PURPOSE",
  UPDATE_PURPOSE_SUCCESS: "UPDATE_PURPOSE_SUCCESS",
  UPDATE_PURPOSE_FAILURE: "UPDATE_PURPOSE_FAILURE",
};
export default roleTypes;
